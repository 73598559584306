import React from "react";
import { Link } from "gatsby";

import { Paragraph1, Label1 } from "baseui/typography";
import { StyledLink } from "baseui/link";

import Page from "../../components/internal-page";
import CheckOKVED from "../../components/check-okved";
import { Heading, HeadingLevel } from "baseui/heading";

export default function Apply({ location }) {
  const [value, setValue] = React.useState(location?.state?.id || "1");
  const okvedList = [
    "13",
    "14",
    "15",
    "16",
    "17",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
  ];
  return (
    <Page
      title="Подать заявку на грант"
      description="Подать заявку на грант в Фонд Развития промышленности Архангельской области"
      path="/frp/apply-for-grant"
      parent="frp"
    >
      <HeadingLevel>
        <Paragraph1>
          <StyledLink
            $as={Link}
            target="_blank"
            to="http://government.ru/dep_news/45217/"
          >
            Постановлением Правительства РФ от 18.04.2022 №686
          </StyledLink>{" "}
          утверждены Правила докапитализации региональных фондов развития
          промышленности из резервного фонда. Выделенные федеральные средства
          через региональные фонды развития промышленности будут направлены на
          предоставление грантов промышленным предприятиям. Правила
          предоставления грантов утверждены Стандартом Фонда
        </Paragraph1>
        <Paragraph1>
          Промышленные предприятия Архангельской области могут ежемесячно
          компенсировать до 90% затрат (но не более ключевой ставки ЦБ),
          понесенных на уплату процентов по банковскому кредиту. Компенсация
          возможна по кредитным договорам, заключенным не ранее 21.04.2022 (либо
          при наличии допсоглашения к кредитного договору, заключенного не ранее
          21.04.2022) в целях пополнения оборотных средств. Основной вид
          деятельности Заявителя должен соответствовать разделу "Обрабатывающие
          производства" по ОКВЭД2 (за исключением классов 10, 11, 12, 18, 19,
          групп 20.53, 20.59, 24.46, подгруппы 20.14.1).
        </Paragraph1>
        <CheckOKVED
          title="Проверить возможность получения гранта"
          okvedList={okvedList}
          bannedOkveds={["20.53", "20.59", "24.46", "20.14.1"]}
        />
        <HeadingLevel>
          <Heading styleLevel={4}>Документы для подачи заявки</Heading>
        </HeadingLevel>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1EnTBkCsa5Fy05V2ov1CnbdAkCMJjOjY4/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Заявление на предоставление гранта
          </StyledLink>
        </Paragraph1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1_xmbGThN91X4hOrJcze-DghgPGK4cbjf/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Заявление о расчетном счете
          </StyledLink>
        </Paragraph1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1uqneCQCQ3KpDaVJ1lk2cNFxonsOb67y-/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Перечень документов для ИП
          </StyledLink>
        </Paragraph1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1SYU08_dhP_RoeRpzlwpUwk-Fwo7KouGH/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Перечень документов для ЮЛ
          </StyledLink>
        </Paragraph1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1AQlSip8tcJr-GqyHKUK7lhg-PMqCvt3Q/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Расчет размера гранта
          </StyledLink>
        </Paragraph1>
        <HeadingLevel>
          <Heading styleLevel={4}>Условия предоставления грантов</Heading>
        </HeadingLevel>
        <Paragraph1>
          <StyledLink
            href="http://static.government.ru/media/files/AYNoqdmNFy3A2VqhpUuBFoNhkoXUhH9J.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Постановление Правительства РФ от 18 апреля 2022 года №686
          </StyledLink>
        </Paragraph1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/17gsaicdWJqHXBXQpicrJaVIXpgf_FRu_/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Стандарт Фонда по предоставлению грантов
          </StyledLink>
        </Paragraph1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1UnSglca6_fZznUa0XCQJcGpWO9HmhGzG/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Целевое использование средств кредита
          </StyledLink>
        </Paragraph1>
        <HeadingLevel>
          <Heading styleLevel={4}>Контактная информация</Heading>
        </HeadingLevel>
        <Paragraph1>Иванова Полина Сергеевна</Paragraph1>
        <Paragraph1>Телефон: (8182) 464122 доб. 107</Paragraph1>
        <Paragraph1>Электронная почта: frp@cmf29.ru</Paragraph1>
        <HeadingLevel>
          <Heading styleLevel={4}>Подача заявок</Heading>
        </HeadingLevel>
        <Label1>Отправка оригиналов почтой по адресу:</Label1>
        <Paragraph1>
          163000, г. Архангельск, ул. Воскресенская, д. 5, а/я №30, Фонд "МКК
          Развитие"
        </Paragraph1>
        <Label1>Передача оригиналов лично по адресу:</Label1>
        <Paragraph1>
          г. Архангельск, ул. Набережная Северной Двины, д. 71 (вход со стороны
          реки).
        </Paragraph1>
      </HeadingLevel>
    </Page>
  );
}
